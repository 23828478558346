import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Flex,
  Text,
  Box,
  useDisclosure,
  Button,
  Img,
  Link,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';

import { TiSocialLinkedin, TiSocialTwitter, TiSocialInstagram } from 'react-icons/ti';

import logoWhite from '../images/workahead-inverse.svg';

import { defaults } from '../util';
import { defaultStyles, workAheadStyles } from '../styles';
import Seo from './seo';
import FeedbackModal from './FeedbackModal';
import Nav from './Nav';

const styles = defaults(defaultStyles, workAheadStyles);

const Layout = forwardRef(({
  title, description, image, location, children,
}, ref) => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  useImperativeHandle(ref, () => ({

    openModal() {
      onOpen();
    },

    closeModal() {
      onClose();
    },

  }));

  return (
    <>
      <FeedbackModal isOpen={isOpen} onClose={onClose} />
      <Seo title={title} description={description} image={image} />
      <Flex
        sx={styles.container}
        bgGradient="linear(to-t, gray.200, white)"
        bgAttachment="fixed"
        px={{ base: 4, md: 8, lg: 0 }}
      >
        <Nav />
        <Flex sx={styles.main}>
          <Box sx={styles.content}>
            {children}
          </Box>
        </Flex>

        <Box bg="gray.800" color="white" sx={{ ...styles.fullWidthBackground }} mt="20">
          <Flex p={{ base: 4, md: 16 }} pt={{ base: 8, md: 16 }} direction={{ base: 'column', md: 'row' }} flexWrap="wrap">
            <Box flex="1 0 100%">
              <Img src={logoWhite} height="5" mb="8" alt="workahead" />
            </Box>
            <Box flex={`1 0 ${100 / 4}%`} pr={{ base: 4, md: 16 }} mb="4">
              <Text as="b">Feedback</Text>
              <Text mb="2">Have suggestions or questions? Something not look right? Send us an email at support@workahead.io</Text>
              <Button onClick={onOpen} variant="outline" colorScheme="white" size="xs">Contact us online.</Button>
            </Box>
            <Box flex={`1 0 ${100 / 2}%`} pr={{ base: 4, md: 16 }} mb="4">
              <Text as="b">Disclaimer</Text>
              <Text>
                This data is presented with no guarantee of accuracy or warranty. Our goal is to have the most up to date data, but it may differ from what you see when you visit a government site, data source, or partner service.
              </Text>
            </Box>
            <Box flex={`1 0 ${100 / 4}%`} pr={{ base: 4, md: 16 }} mb="4">
              <Text as="b">Follow us</Text>
              <List>
                <ListItem>
                  <Link href="https://www.linkedin.com/company/work-ahead-io" isExternal>
                    <ListIcon as={TiSocialLinkedin} color="white" />
                    LinkedIn
                  </Link>
                </ListItem>

                <ListItem>
                  <Link href="https://twitter.com/workahead_io" isExternal>
                    <ListIcon as={TiSocialTwitter} color="white" />
                    Twitter
                  </Link>
                </ListItem>

                <ListItem>
                  <Link href="https://www.instagram.com/work_ahead/" isExternal>
                    <ListIcon as={TiSocialInstagram} color="white" />
                    Instagram
                  </Link>
                </ListItem>

              </List>
            </Box>
          </Flex>
          <Flex p={{ base: 4, md: 8 }} justifyContent="center">
            <Text as="span" mr="4">{`© ${new Date().getFullYear()}`}&nbsp;<b>Work ahead</b></Text>
            <Text as="span">All Rights Reserved</Text>
          </Flex>
        </Box>
      </Flex>
    </>
  );
});

export default Layout;
