import React, { useRef } from 'react';
import {
  Flex,
  Text,
  Link,
  Box,
  Stack,
  useDisclosure,
  Button,
  Img,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Divider,
  Heading,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Link as GLink } from 'gatsby';

import logo from '../images/workahead.svg';
import { defaults } from '../util';
import { defaultStyles, workAheadStyles } from '../styles';

const styles = defaults(defaultStyles, workAheadStyles);

const navItems = {
  Articles: [
    {
      text: 'How To',
    },
    {
      href: '/articles/how-to-choose-the-right-job',
      text: 'Choose the right job',
    },
    {
      href: '/articles/how-to-evaluate-job-stability',
      text: 'Evaluate job stability',
    },
  ],
  Tools: [
    {
      text: 'Calculators',
    },
    {
      href: '/flexible-work/calculator',
      text: 'Flexible Work',
    },
    {
      href: '/job-stability/calculator',
      text: 'Job Stability',
    },
    {
      href: '/wages/calculator',
      text: 'Wages',
    },
  ],
};

const BigNav = () => (
  <Box flex="1 0" textAlign="right" display={{ base: 'none', md: 'block' }}>
    {
      Object.entries(navItems).map(([navLabel, items]) => (
        <Popover
          key={navLabel}
          trigger="hover"
          openDelay={0}
          closeDelay={150}
        >
          <PopoverTrigger>
            <Box display="inline-flex" mr="4" alignItems="center">
              <Text as="span" fontSize="sm" sx={{ '&:hover': { cursor: 'pointer' } }}>{navLabel}</Text>
              <ChevronDownIcon boxSize="18" />
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="auto">
              <PopoverArrow />
              <PopoverBody py="2" px="4">
                <Stack spacing="1">
                  {
                    items.map(item => {
                      if (item.href) return <Link key={item.text} as={GLink} to={item.href}>{item.text}</Link>;
                      return <Heading size="xs" color="gray">{item.text}</Heading>;
                    })
                  }
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      ))
    }
  </Box>
);

const LilNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <Box flex="1 0" textAlign="left" display={{ md: 'none' }}>
      <Button ref={btnRef} variant="ghost" onClick={onOpen} px="0">
        <HamburgerIcon w={5} h={5} />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody pt="12">
            {
              Object.entries(navItems).map(([navLabel, items]) => (
                <Stack mb="8">
                  <Heading as="span" size="md">{navLabel}</Heading>
                  {
                    items.map(item => {
                      if (item.href) return <Link key={item.text} as={GLink} to={item.href}>{item.text}</Link>;
                      return <Heading size="xs" color="gray">{item.text}</Heading>;
                    })
                  }
                </Stack>
              ))
            }
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

const Nav = () => (
  <Flex sx={styles.header}>
    <Link as={GLink} to="/">
      <Img sx={{ ...workAheadStyles.logoType }} src={logo} alt="workahead" />
    </Link>
    <Box >
      <LilNav />
    </Box>
    <Box >
      <BigNav />
    </Box>
  </Flex>
);

export default Nav;
