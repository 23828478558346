const defaultStyles = {
  header: {
    display: 'flex',
    height: '80px',
  },
  container: {
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    flex: 1,
  },
};

const workAheadStyles = {
  header: {
    borderBottom: 'dashed 1px',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    position: 'relative',
    color: 'black',
    width: '100%',
    maxWidth: '960px',
    margin: '0 auto',
    height: '60px',
  },
  logoType: {
    position: 'absolute',
    left: '50%',
    bottom: '0',
    transform: 'translateY(40%) translateX(-50%)',
    backgroundColor: 'bg',
    paddingLeft: '4px',
    paddingRight: '10px',
  },
  container: {
    backgroundColor: 'bg',
  },
  content: {
    flexDirection: 'column',
    width: '100%',
    maxWidth: '960px',
    margin: '0 auto',
    paddingTop: '60px',
  },
  bigHeading: {
    fontSize: '6xl',
    lineHeight: 1,
    fontWeight: 100,
  },
  lilHeading: {
    fontSize: 5,
    lineHeight: 1,
    fontWeight: 200,
  },
  inputs: {
    flexWrap: 'wrap',
    justifyContent: { base: 'flex-start', md: 'space-between' },
  },
  input: {
    flex: { base: '1', md: '0 0 32%' },
    mb: {base:8, md:8},
  },
  submitButton: {
    flex: { base: '1', md: '0 0 80px' },
    marginLeft: { base: 0, md: 'auto' },
    height: {base: 24, md: 12},
  },
  fullWidthBackground: {
    position: 'relative',
    zIndex: 1,
    '::before': {
      content: '""',
      width: '100vw',
      height: '100%',
      position: 'absolute',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      backgroundColor: 'inherit',
      zIndex: -1,
      top: 0,
    },
  },
};

const growthBookStyles = {
  header: {
    borderBottom: 'solid 2px',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'black',
    width: '100%',
    padding: 4,
  },
  logoType: {
    fontFamily: 'Lora, san-serif',
    fontSize: 6,
    fontWeight: 800,
    letterSpacing: 0,
  },
  container: {
    backgroundColor: 'white',
  },
  main: {
    width: '100%',
    maxWidth: 1300,
    margin: '0 auto',
    alignItems: 'flex-start',
    padding: 4,
  },
  content: {
    display: 'flex',
    minHeight: 450,
  },
  bigHeading: {
    fontSize: 4,
    fontWeight: 400,
  },
  context: {
    width: '50%',
    padding: 6,
    backgroundColor: '#063A41',
    color: 'white',
    borderRadius: '4px 0 0 4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  inputs: {
    width: '50%',
    padding: 6,
    flexDirection: 'column',
    flexWrap: 'wrap',
    backgroundColor: '#ADD2C5',
    borderRadius: '0 4px 4px 0',
  },
  input: {
    borderColor: '#ADD2C5',
    backgroundColor: 'white',
    marginBottom: 3,
  },
  submitButton: {
    backgroundColor: '#F3BF59',
    borderRadius: 8,
    width: '100%',
  },
};

export {
  defaultStyles,
  workAheadStyles,
  growthBookStyles,
};
