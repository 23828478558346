import axios from 'axios';

const get = require('lodash.get');
const mergeWith = require('lodash.mergewith');

const server = process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : 'https://api.workahead.io';

const mapDataToVariants = (nodes, mapping) => nodes.reduce((variants, node) => {
  // variant is an array because of how airtable links it, but we only care about 1:1
  if (node.data.variant === null) return variants;
  const variant = node.data.variant[0];
  return {
    ...variants,
    [variant.data.name]: Object.entries(mapping).reduce((data, [key, value]) => ({
      ...data,
      [key]: get(node.data, value),
    }), {}),
  };
}, {});

const mapDataToText = (mapping, value) => {
  const sorted = mapping.sort((a, b) => a.min - b.min);
  return sorted.find(
    values => (parseFloat(value) > parseFloat(values.min) && value <= parseFloat(values.max)),
  );
};

const defaults = (...args) => mergeWith(
  {},
  ...args,
  (a, b) => ((b === null || b === undefined || b === '') ? a : undefined),
);

const template = (templateString, templateVariables) => templateString.replace(/\${(.*?)}/g, (_, g) => get(templateVariables, g));

const logEvent = (action, category, label) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  }
};

const ping = () => {
  axios.get(`${server}/ping`);
};

const getLocalStorage = key => {
  if (typeof window !== 'undefined' && window.localStorage) {
    return window.localStorage.getItem(key);
  }
  return null;
};

const setLocalStorage = (key, value) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.setItem(key, value);
  }
};

const shuffleArray = arr => arr.map(n => [Math.random(), n]).sort().map(n => n[1]);

const randomizeOrder = (key, initialArray) => {
  const order = getLocalStorage(key);
  if (order) {
    const arr = order.split(',');
    if (arr.length > 1) {
      return arr;
    }
  }
  const newOrder = shuffleArray(initialArray);
  setLocalStorage(key, newOrder);
  return newOrder;
};

export {
  mapDataToVariants,
  mapDataToText,
  defaults,
  template,
  logEvent,
  ping,
  randomizeOrder
};
