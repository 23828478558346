import React, { useState } from 'react';
import {
  Text,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Textarea,
} from '@chakra-ui/react';
import axios from 'axios';

const server = process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : 'https://api.workahead.io';

const FeedbackModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState('');

  const submit = () => {
    const data = { email, response };
    const formData = Object.entries(data).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join('&');
    if (email || response) {
      axios({
        method: 'post',
        url: `${server}/feedback`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: formData,
      }).then(data => console.log(data));
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Contact us</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="6">Work ahead is currently in alpha testing and some features are still in development. Your interest helps us understand what to build next.</Text>
          <Text mb="6">If you would like to be kept up to date as we launch new features, or have any thoughts or comments about your experience so far, please let us know by leaving your email below. We are open to any and all suggestions.</Text>
          <Text mb="6">Thank you!</Text>
          <Input mb="2" type="email" placeholder="Email address" onChange={e => setEmail(e.currentTarget.value)} />
          <Textarea placeholder="Comments, Requests, or Feedback" onChange={e => setResponse(e.currentTarget.value)} />
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Close</Button>
          <Button colorScheme="blue" mr={3} onClick={submit}>
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;
